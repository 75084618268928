import React from "react";
import Rajshahi from "../../assets/Rajshahi.png";
import Khulna from "../../assets/Khulna.png";
import Chattogram from "../../assets/Chattogram.png";
import Sylhet from "../../assets/Sylhet.png";
import school from "../../assets/school360_of_bangladesh.png";
import Rangpur from "../../assets/Rangpur.png";
import Mymensingh from "../../assets/Mymensingh.png";
import Barishal from "../../assets/Barishal.png";
import Dhaka from "../../assets/Dhaka.png";

const DistrictSection = () => {
  return (
    <div className="pb-20 flex flex-col items-center gap-4 ">
      <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit ">
        সারাদেশে “স্কুল৩৬০” এর বিস্তার
      </h1>
      <div>
        <h1 className="text-4xl font-bold font-bangla mb-12">
          বাংলাদেশের প্রতিটি বিভাগে পৌঁছে গেছে “স্কুল৩৬০”।
        </h1>
      </div>
      <div className="grid grid-cols-3 content-center justify-center">
        <div className="flex flex-col items-center justify-center gap-16">
          <img src={Rajshahi} alt="" className="w-28" />
          <img src={Khulna} alt="" className="w-28" />
          <img src={Chattogram} alt="" className="w-28" />
        </div>
        <div className="flex flex-col items-center justify-center gap-16">
          <img src={Sylhet} alt="" className="w-28" />
          <img src={school} alt="" className="w-72" />
          <img src={Rangpur} alt="" className="w-28" />
        </div>
        <div className="flex flex-col items-center justify-center gap-16">
          <img src={Mymensingh} alt="" className="w-28" />
          <img src={Barishal} alt="" className="w-28" />
          <img src={Dhaka} alt="" className="w-28" />
        </div>
      </div>
    </div>
  );
};

export default DistrictSection;
