import React from "react";
import facebook_rating from "../../assets/facebook_rating.svg";
import google_rating from "../../assets/google_rating.svg";

const RatingSection = () => {
  return (
    <div className="mb-20">
      <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit m-auto mb-4">
        রেটিং
      </h1>
      <h1 className="text-4xl font-bold font-bangla mb-20">
        আমাদের মূল্যায়ন করে
      </h1>
      <div className="flex flex-col md:flex-row justify-center items-center gap-6">
        <img
          src={facebook_rating}
          alt=""
          className="border-dotted px-20 py-10 border-2 border-red-400 rounded-lg w-3/5 md:w-[22rem] lg:w-[25rem]"
        />
        <img
          src={google_rating}
          alt=""
          className="border-dotted px-20 py-10 border-2 border-red-400 rounded-lg w-3/5 md:w-[22rem] lg:w-[25rem]"
        />
      </div>
    </div>
  );
};

export default RatingSection;
