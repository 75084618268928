import React from "react";
import { Link } from "react-router-dom";

const ToggleBar = ({ colorChange, toggle }) => {
  return (
    <div>
      <div
        className={`${
          !toggle && "hidden"
        } flex flex-col gap-2 p-3 bg-white items-center mx-10 md:mx-20`}
      >
        <Link
          to="/"
          className={` font-semibold border-2 w-full py-2 rounded-md hover:cursor-pointer text-[#888]`}
        >
          মূল পাতা
        </Link>
        <Link
          to="/software-features"
          className={` font-semibold border-2 w-full py-2 rounded-md hover:cursor-pointer text-[#888]`}
        >
          ফীচার
        </Link>
        <div className="dropdown w-full">
          <nav
            className={` font-semibold border-2 w-full py-2 rounded-md hover:cursor-pointer text-[#888]`}
          >
            কোম্পানি
          </nav>
          <div className="dropdown-content shadow-lg">
            <Link to="/about-us" className="text-sm text-start">
              আমাদের সম্পর্কে
            </Link>
            <Link to="/our-management-team" className="text-sm text-start">
              ব্যবস্থাপনা টিম
            </Link>
            <Link to="/teacher-comments" className="text-sm text-start">
              শিক্ষকদের মন্তব্য
            </Link>
            <Link to="/news-and-events" className="text-sm text-start">
              সংবাদমাধ্যম এবং মুহূর্ত
            </Link>
            <Link to="/photo-gallery" className="text-sm text-start">
              ফটো গ্যালারি
            </Link>
          </div>
        </div>
        <div className="dropdown w-full">
          <nav
            className={` font-semibold border-2 w-full py-2 rounded-md hover:cursor-pointer text-[#888]`}
          >
            সল্যুশন
          </nav>
          <div className="dropdown-content shadow-lg">
            <Link to="/faq" className="text-sm text-start">
              প্রশ্ন উত্তর
            </Link>
          </div>
        </div>
        <div className="dropdown w-full">
          <nav
            className={` font-semibold border-2 w-full py-2 rounded-md hover:cursor-pointer text-[#888]`}
          >
            পার্টনার
          </nav>
          <div className="dropdown-content shadow-lg">
            <Link to="/join-us" className="text-sm text-start">
              রেজিস্ট্রেশন করুন
            </Link>
            <Link to="/login" className="text-sm text-start">
              লগইন করুন
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleBar;
