import React from "react";

const AccessSection = () => {
  return (
    <div className="agency1 w-screen mb-20 py-16">
      <h1 className="text-xl md:text-3xl lg:text-5xl text-white font-bold font-bangla mb-16 w-1/2 md:3/4 lg:w-2/4 mx-auto">
        আপনি কী আপনার শিক্ষা প্রতিষ্ঠান ডিজিটাল রূপান্তর করতে প্রস্তুত?
      </h1>
      <h1 className="text-xl px-4 py-1 mb-4 bg-white rounded font-bangla w-fit mx-auto">
        আইডি রিকোয়েস করুন
      </h1>
    </div>
  );
};

export default AccessSection;
