import React, { useEffect, useRef, useState } from "react";
import student_portal_app from "../../../assets/student_portal_app.png";
import national_university_management_software from "../../../assets/national_university_management_software.png";
import awardPhoto from "../../../assets/awardPhoto.png";
import StudentPortal from "./StudentPortal";
import National from "./National";
import Award from "./Award";
import Navbar from "../../Shared/Navbar";

const banners = [
  { sect: <StudentPortal />, image: student_portal_app },
  { sect: <National />, image: national_university_management_software },
  { sect: <Award />, image: awardPhoto },
];

const Banner = () => {
  const myElementRef = useRef();
  const [slideIndex, setSlideIndex] = useState(1);

  const showSlides = (n) => {
    if (myElementRef.current) {
      let slides = myElementRef.current.getElementsByClassName("mySlides");
      for (let i = 0; i < banners.length; i++) {
        slides[i].style.display = "none";
      }
      slides[slideIndex - 1].style.display = "grid";
    }
  };

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  const plusSlides = (n) => {
    if (slideIndex + n > banners.length) showSlides(setSlideIndex(1));
    else if (slideIndex + n < 1) showSlides(setSlideIndex(banners.length));
    else showSlides(setSlideIndex(slideIndex + n));
  };

  return (
    <div>
      <div className="lg:h-[44rem] banner mb-20" ref={myElementRef}>
        <div className="fixed top-0 z-20 w-full">
          <Navbar />
        </div>
        <div className="relative top-20 lg:overflow-y-scroll">
          {banners.map((banner, i) => (
            <div
              className="lg:mb-20 py-16 px-28 lg:grid lg:grid-cols-2 mySlides fade"
              key={i}
            >
              {banner.sect}
              <img
                src={banner.image}
                alt=""
                className="relative z-10 lg:w-full md:w-4/5 md:mx-auto mt-10 lg:mt-0"
              />
            </div>
          ))}
        </div>
        <p
          className="prev border-2 border-gray-300 mx-3 rounded-lg"
          onClick={() => plusSlides(-1)}
        >
          ❮
        </p>
        <p
          className="next border-2 border-gray-300 mx-3 rounded-lg"
          onClick={() => plusSlides(1)}
        >
          ❯
        </p>
        <div className="custom-shape-divider-bottom-1698915649">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Banner;
