import React from "react";
import customer_suppport from "../../assets/customer-support.svg";
import error_free from "../../assets/error-free-result.svg";
import focusmore from "../../assets/focusmore.svg";
import money_receipt from "../../assets/moneyreceipt-in-tenseconds.svg";
import relationship from "../../assets/relationship-buildup.svg";
import reports from "../../assets/reports-in-seconds.svg";
import reputation from "../../assets/reputation.svg";
import security from "../../assets/security-backup.svg";
import transparent_account from "../../assets/transparent-accounts.svg";

const benefits = [
  {
    image: customer_suppport,
    work: "হিসাব বিভাগকে নিয়ন্ত্রণ করুন",
    colored: "স্বচ্ছতার সঙ্গে",
    benefit: "আর থাকুন নিশ্চিন্ত ও ঝুঁকিমুক্ত",
  },
  {
    image: error_free,
    work: "অতিরিক্ত ঝামেলা ছাড়া",
    colored: "নির্ভুলভাবে",
    benefit: "যথাসময়ে ফলাফল প্রকাশ করুন",
  },
  {
    image: focusmore,
    work: "প্রতিবেদনের বিবৃতি পান",
    colored: "মূহুর্তে",
    benefit: "এবং কর্মক্ষমতা প্রর্যবেক্ষন করুন",
  },
  {
    image: money_receipt,
    work: "বেতনের রশিদ তৈরি করুন",
    colored: "১০ সেকেন্ডে",
    benefit: "এবং পেমেন্ট এসএমএস পান",
  },
  {
    image: reports,
    work: "প্রাতিষ্ঠানিক কাজ কমিয়ে",
    colored: "মনযোগী হউন",
    benefit: "শিক্ষার্থীদের পড়াশোনার প্রতি",
  },
  {
    image: reputation,
    work: "শিক্ষক, শিক্ষার্থী ও অভিভাবকদের মধ্যে",
    colored: "সুসম্পর্ক",
    benefit: "স্থাপনে সাহায্য করবে",
  },
  {
    image: relationship,
    work: "শিক্ষক, অভিভাবক ও শিক্ষার্থীর সন্তুষ্টির সাথে",
    colored: "প্রতিষ্ঠানের খ্যাতি",
    benefit: "বৃদ্ধি করুন",
  },
  {
    image: security,
    work: "উন্নত ও শক্তিশালী ডাটা",
    colored: "নিরাপত্তা ও ব্যাকআপ",
    benefit: "গোপনীয়তা এবং নিরাপত্তা নিশ্চিত করুন",
  },
  {
    image: transparent_account,
    work: "পাবেন আন্তরিক",
    colored: "সাপোর্ট",
    benefit: "নিবেদিত ও বিশ্বস্ত টিম দ্বারা",
  },
];

const Benefits = () => {
  return (
    <div className="bg-[#f4f7fa] w-screen py-20">
      <h1 className="text-3xl bg-red-600 w-fit mx-auto mb-6 text-white px-4 py-1 bg-grad rounded font-bangla">
        কেন “স্কুল৩৬০” সফটওয়্যারটি অনন্য?
      </h1>
      <h1 className="text-2xl w-3/4 mx-auto md:text-4xl font-bold font-bangla mb-12">
        কিভাবে “স্কুল৩৬০” সফটওয়্যারটি আপনার উপকার করতে পারে?
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-y-12 px-20 lg:gap-y-16">
        {benefits.map((benefit, i) => (
          <div key={i}>
            <div className="shadow-2xl m-auto rounded-xl bg-white py-4 w-96 h-76 md:h-[18rem] md:w-5/6 lg:h-[20rem] lg:w-80">
              <img src={benefit.image} alt="" className="w-40 lg:w-52 m-auto" />
              <p className="text-[#666] font-bangla text-xl font-semibold">
                {benefit.work}
              </p>
              <p className="bg-[#f79a23] text-white font-bangla text-2xl font-bold w-fit m-auto px-3">
                {benefit.colored}
              </p>
              <p className="text-[#666] font-bangla text-xl font-semibold">
                {benefit.benefit}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
