import React from "react";
import sms from "../../assets/sms.svg";

const services = [
  {
    name: "এইচআর উপস্থিতি রিপোর্ট",
    des: "আপনার কর্মীদের দৈনিক উপস্থিতির প্রতিবেদন পেয়ে যাবেন।",
  },
  {
    name: "অটো ডে ক্লোজিং রিপোর্ট",
    des: "স্বয়ংক্রিয়ভাবে আপনার প্রতিষ্ঠানের দৈনিক আয়, ব্যয়ের রিপোর্ট পেয়ে যাবেন।",
  },
  {
    name: "এসএমএস/ই-মেইল বিজ্ঞপ্তি",
    des: "আপনার স্মার্টফোনে এসএমএস / ই-মেইল বিজ্ঞপ্তি পেয়ে যাবেন।",
  },
];

const SmsSection = () => {
  return (
    <div>
      <div className="py-20 lg:flex justify-center items-center px-8 md:px-28">
        <div className="flex flex-col gap-12">
          <div>
            <div className="">
              <h1 className="text-3xl mb-4 bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit ">
                নিশ্চিন্তে থাকুন
              </h1>
              <h1 className="text-4xl mb-7 font-bold font-bangla text-start">
                আপনার প্রতিষ্ঠান এখন হাতের মুঠোয়
              </h1>
              <p className="text-[#888] text-start w-4/5">
                এসএমএস এর মাধ্যমে প্রতিদিনের আর্থিক প্রতিবেদন পেয়ে যাবেন।
                রিপোর্টের জন্য আপনার অ্যাকাউন্টস ম্যানেজারকে জিজ্ঞাসা করার
                প্রয়োজন নেই
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-7 ">
            {services.map((service, i) => (
              <div key={i} className="flex  items-center gap-6">
                <p className="bg-button_grad_2 px-6 py-5 rounded-xl">ha</p>
                <div>
                  <h1 className="text-2xl font-bold font-bangla text-start">
                    {service.name}
                  </h1>
                  <p className="text-[#888] text-start font-bangla text-lg">
                    {service.des}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img src={sms} alt="" className="w-[40rem]" />
      </div>
    </div>
  );
};

export default SmsSection;
