import React from "react";
import useTitle from "../hooks/useTitle";

const TeacherComments = () => {
  useTitle(" শিক্ষকদের মন্তব্য");
  return (
    <div className="py-20 px-16 lg:px-28 h-fit">
      <h1 className="text-2xl font-bold mb-3">
        “স্কুল৩৬০” সম্পর্কে আরও জানতে নিম্নের ভিডিওগুলি দেখুন
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <iframe
          //   width="560"
          height="315"
          src="https://www.youtube.com/embed/0Fbcuxob_m0?si=GAAAZBRpRDVDgqXt"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full"
        ></iframe>
        <iframe
          // width="560"
          height="315"
          src="https://www.youtube.com/embed/0Fbcuxob_m0?si=GAAAZBRpRDVDgqXt"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full"
        ></iframe>
        <iframe
          // width="560"
          height="315"
          src="https://www.youtube.com/embed/0Fbcuxob_m0?si=GAAAZBRpRDVDgqXt"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full"
        ></iframe>
        <iframe
          // width="560"
          height="315"
          src="https://www.youtube.com/embed/0Fbcuxob_m0?si=GAAAZBRpRDVDgqXt"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full"
        ></iframe>
        <iframe
          // width="560"
          height="315"
          src="https://www.youtube.com/embed/0Fbcuxob_m0?si=GAAAZBRpRDVDgqXt"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full"
        ></iframe>
      </div>
    </div>
  );
};

export default TeacherComments;
