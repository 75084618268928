import React from "react";

const other_modules = [
  { icon: "", name: "ডিজিটাল হাজিরা সিস্টেম" },
  { icon: "", name: "ছাত্র/ছাত্রী, শিক্ষক, কর্মচারী ব্যবস্থাপনা" },
  { icon: "", name: "রেজাল্ট ব্যবস্থাপনা" },
  { icon: "", name: "স্টুডেন্ট একাউন্স ব্যবস্থাপনা এবং রিপোর্টস" },
  { icon: "", name: "বেসিক একাউন্স ব্যবস্থাপনা এবং রিপোর্টস" },
  { icon: "", name: "ইনভেন্টরি ব্যবস্থাপনা" },
  { icon: "", name: "পরিবহন ব্যবস্থাপনা" },
  { icon: "", name: "পে-রোল ব্যবস্থাপনা" },
  { icon: "", name: "লেকচার, পাঠ পরিকল্পনা ও বাড়ীর কাজ" },
  { icon: "", name: "ভয়েস এসএমএস" },
  { icon: "", name: "মাস্কিং এবং নন মাস্কিং এসএমএস নোটিফিকেশন" },
  { icon: "", name: "শিক্ষার্থী ও শিক্ষক এবং অভিভাবক পোর্টাল" },
  { icon: "", name: "ক্লাস রুটিন ও পরীক্ষার রুটিন ব্যবস্থাপনা" },
  { icon: "", name: "অনলাইনে ভর্তি প্রক্রিয়া সম্পন্ন করা" },
  { icon: "", name: "সম্পূর্ণরুপে ইন্টিগ্রেটেড ডাইনামিক ওয়েবসাইট" },
];

const OtherModule = () => {
  return (
    <div className=" bg-[#f4f7fa] w-screen pb-28">
      <h1 className="text-4xl font-bold font-bangla">অন্যান্য মডিউল</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 w-3/4 m-auto mt-20">
        {other_modules.map((module, i) => (
          <div key={i}>
            <p className="text-lg font-semibold bg-white shadow-sm py-4 text-start ps-7 font-bangla">
              {module.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherModule;
