import React, { useState } from "react";
import supported_by from "../../assets/supported_by.png";
import CascadingDropdownExample from "../JoinUs/CascadingDrop";
import useTitle from "../hooks/useTitle";

const SoftwareUse = () => {
  useTitle("সফটওয়্যার ব্যবহার করতে চাই");
  const [selectedEducation, setSelectedEducation] = useState("");
  return (
    <form className="md:w-4/5 lg:w-3/5 md:mx-auto border border-[#0d6efd] rounded mx-10 px-5 py-10 my-10 mt-20">
      <img src={supported_by} alt="" className="w-2/6 mx-auto mb-6" />
      <div>
        <div className="flex flex-col mt-3">
          <label
            htmlFor="institute"
            className="text-[#888] font-bangla font-bold text-start"
          >
            Institute Name (প্রতিষ্ঠানের নাম)
            <span className="text-red-500 text-2xl">*</span>
          </label>
          <input
            type="text"
            id="institute"
            name="institute"
            placeholder="ENTER YOUR INSTITUTE NAME"
            className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
          <div className="flex flex-col">
            <label
              htmlFor="person"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Contact Person (যোগাযোগের জন্য নিযুক্ত ব্যক্তি){" "}
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <input
              type="text"
              id="person"
              name="person"
              placeholder="CONTACT PERSON NAME"
              className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="mobile"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Personal Mobile (ব্যাক্তিগত মোবাইল)
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              placeholder="ENTER YOUR MOBILE"
              className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
            />
            <p className="font-bangla text-sky-400 text-start text-sm flex items-center gap-1">
              <span className="text-2xl">***</span> এই নাম্বারে SMS পাঠনো হবে
            </p>
          </div>
        </div>
        <CascadingDropdownExample />
        <div className="my-5">
          <p className="text-[#888] font-bold text-start text-sm">
            Institute Address (প্রতিষ্ঠানের ঠিকানা)
            <span className="text-red-500 text-2xl">*</span>
          </p>
          <textarea
            name="Position"
            id="position"
            cols="30"
            rows="4"
            className="border border-black mt-5 w-full rounded"
          ></textarea>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col mt-3">
            <label
              htmlFor="education"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Institute Type (প্রতিষ্ঠানের ধরণ)
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <select
              name="education"
              id="education"
              value={selectedEducation}
              onChange={(e) => setSelectedEducation(e.target.value)}
              className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#000] appearance-none"
            >
              <option value="" selected="selected">
                SELECT - নির্বাচন করুন
              </option>
              <option value="School - স্কুল" selected="selected">
                School - স্কুল
              </option>
              <option value="College - কলেজ" selected="selected">
                College - কলেজ
              </option>
              <option value="Madrasah - মাদরাসা" selected="selected">
                Madrasah - মাদরাসা
              </option>
              <option value="Kindergarten - কিন্ডারগার্টেন" selected="selected">
                Kindergarten - কিন্ডারগার্টেন
              </option>
              <option value="Polytechnic - পলিটেকনিক" selected="selected">
                Polytechnic - পলিটেকনিক
              </option>
            </select>
          </div>
          <div className="flex flex-col mt-3">
            <label
              htmlFor="number_of_student"
              className="text-[#888] text-sm font-bold text-start"
            >
              Number of Student (শিক্ষার্থীর সংখ্যা)
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <input
              type="number"
              id="number_of_student"
              name="number_of_student"
              placeholder="Number of Student"
              className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
            />
          </div>
        </div>
        <button className="text-white bg-[#dc3545] w-fit rounded py-2 px-3 mt-5 flex">
          আবেদন করুন - Submit
        </button>
      </div>
    </form>
  );
};

export default SoftwareUse;
