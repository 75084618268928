import { createBrowserRouter } from "react-router-dom";
import Main from "../../layouts/Main";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import SoftwareUse from "../SoftwareUse/SoftwareUse";
import Feature from "../Feature/Feature";
import Faq from "../FAQ/Faq";
import JoinUs from "../JoinUs/JoinUs";
import Registration from "../JoinUs/Registration";
import PhotoGallery from "../Company/PhotoGallery";
import MediaEvent from "../Company/MediaEvent";
import TeacherComments from "../Company/TeacherComments";
import Management from "../Company/Management";
import AboutUs from "../Company/AboutUs";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/iir",
        element: <SoftwareUse />,
      },
      {
        path: "/software-features",
        element: <Feature />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/join-us",
        element: <JoinUs />,
      },
      {
        path: "/reg",
        element: <Registration />,
      },
      {
        path: "/photo-gallery",
        element: <PhotoGallery />,
      },
      {
        path: "/news-and-events",
        element: <MediaEvent />,
      },
      {
        path: "/teacher-comments",
        element: <TeacherComments />,
      },
      {
        path: "/our-management-team",
        element: <Management />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
