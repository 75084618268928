import React, { useRef, useState } from "react";
import useTitle from "../hooks/useTitle";

const sections = [
  {
    title: "এটা কি ডেস্কটপ ভিত্তিক বা অফলাইন সফটওয়্যার?",
    content:
      "আমাদের সফটওয়্যার একটি ওয়েব ভিত্তিক সফটওয়্যার, ডেস্কটপ ভিত্তিক বা অফলাইন সফটওয়্যার নয়। ক্লাউড ভিত্তিক সফটওয়্যারগুলি জনপ্রিয় হয়ে উঠছে কারণ এটি যে কোনও ডিভাইসের মাধ্যমে যেকোনো স্থান থেকে ব্যবহার করা যায়।",
  },
  {
    title: "এটা কি ডেস্কটপ ভিত্তিক বা অফলাইন সফটওয়্যার?2",
    content:
      "আমাদের সফটওয়্যার একটি ওয়েব ভিত্তিক সফটওয়্যার, ডেস্কটপ ভিত্তিক বা অফলাইন সফটওয়্যার নয়। ক্লাউড ভিত্তিক সফটওয়্যারগুলি জনপ্রিয় হয়ে উঠছে কারণ এটি যে কোনও ডিভাইসের মাধ্যমে যেকোনো স্থান থেকে ব্যবহার করা যায়।",
  },
];

const Faq = () => {
  useTitle("প্রশ্ন উত্তর");
  const myElementRef = useRef();
  const [index, setIndex] = useState(null);

  const showAccordion = (i) => {
    let slides = myElementRef.current.getElementsByClassName("accordion");
    let slides2 = myElementRef.current.getElementsByClassName("hide");

    if (index == null) {
      setIndex(i);
      if (myElementRef.current) {
        slides[i].style.background = "linear-gradient(#090588, #c82525 80%)";
        slides[i].style.color = "white";
        slides2[i].style.display = "block";
      }
    } else {
      setIndex(null);
      if (myElementRef.current) {
        slides[index].style.background = "white";
        slides[index].style.color = "black";
        slides2[index].style.display = "none";
      }
    }
  };

  return (
    <div className="my-20" ref={myElementRef}>
      <h1 className="bg-grad text-start text-white px-3 py-2 rounded text-2xl w-fit mx-auto">
        প্রশ্ন উত্তর
      </h1>
      <p className=" text-[#888] px-5 md:px-0 md:w-3/5 mx-auto mt-3 mb-10">
        আমরা বিশ্বাস করি আপনার মাথায় অনেক প্রশ্নের উদয় হয়েছে আমাদের সফটওয়্যার
        নিয়ে এবং আপনি ওই প্রশ্নগুলির উত্তর খোঁজার জন্য এইখানে এসেছেন। আপনার
        সহযোগিতার জন্য আমাদেরকে জিজ্ঞাসাকৃত প্রশ্নের উত্তর নিম্নে দেয়ার
        প্রচেষ্টা করেছি মাত্র।
      </p>

      <div className="px-5 md:px-0 md:w-3/5 mx-auto">
        {sections.map((section, i) => (
          <div key={i} className="shadow-2xl mb-8 ">
            <button
              className="text-start p-4 w-full accordion"
              onClick={() => showAccordion(i)}
            >
              {section.title}
            </button>
            <div className="hidden hide">
              <p className="text-start text-[#888] px-5 py-8">
                {section.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
