import React from "react";
import dashboard from "../../assets/Dashboard.png";
import attendance_solutions from "../../assets/attendance_solutions.svg";
import barcode_scanner from "../../assets/barcode_scanner.svg";
import android_app from "../../assets/android_app.svg";
import data_export from "../../assets/data_export.svg";
import sms_email_notifications from "../../assets/sms_email_notifications.svg";
import dynamic_website from "../../assets/dynamic_website.svg";
import payment_gateway from "../../assets/payment_gateway.svg";
import online_admission from "../../assets/online_admission.svg";

const SinglePointSolution = () => {
  return (
    <div className="py-20 flex flex-col items-center gap-4 ">
      <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit ">
        সিঙ্গেল পয়েন্ট সমাধান
      </h1>
      <div>
        <p className="text-[#888] w-3/4 mx-auto md:w-full">
          আপনি আমাদের স্কুল কলেজ ব্যবস্থাপনা সফটওয়্যার থেকে আপনার প্রয়োজনীয়
          সমস্ত সমাধান পাবেন।
        </p>
      </div>
      <div className="grid grid-cols-3 content-center justify-center">
        <div className="flex flex-col items-center justify-center gap-8 md:gap-16">
          <img src={sms_email_notifications} alt="" className="w-16 md:w-28" />
          <img src={barcode_scanner} alt="" className="w-16 md:w-28" />
          <img src={data_export} alt="" className="w-16 md:w-28" />
        </div>
        <div className="flex flex-col items-center justify-center gap-8 md:gap-16">
          <img src={payment_gateway} alt="" className="w-16 md:w-28" />
          <img src={dashboard} alt="" className="w-40 md:w-72" />
          <img src={android_app} alt="" className="w-16 md:w-28" />
        </div>
        <div className="flex flex-col items-center justify-center gap-8 md:gap-16">
          <img src={online_admission} alt="" className="w-16 md:w-28" />
          <img src={attendance_solutions} alt="" className="w-16 md:w-28" />
          <img src={dynamic_website} alt="" className="w-16 md:w-28" />
        </div>
      </div>
    </div>
  );
};

export default SinglePointSolution;
