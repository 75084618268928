import React from "react";

const StudentPortal = () => {
  return (
    <div>
      <div>
        <h1 className="text-xl font-bold text-start text-[#ffe739] rounded font-bangla">
          ইউজার ফ্রেন্ডলি
        </h1>
        <h1 className=" text-5xl text-start text-white font-bold font-bangla mb-10">
          শিক্ষার্থী পোর্টাল অ্যাপ
        </h1>
        <h1 className=" bg-[#c40189] text-white rounded text-xl text-start  font-bangla mb-5 w-fit  px-2 py-1">
          ডাউনলোড জোন থেকে অ্যান্ড্রয়েড অ্যাপ ডাউনলোড করুন খুব সহজে
        </h1>
        <div>
          <p className="text-xl text-white font-bangla text-start">
            শিক্ষার্থী প্রোফাইল
          </p>
          <p className="text-xl text-white font-bangla text-start">
            বাড়ির কাজ, ডায়েরি নোট
          </p>
          <p className="text-xl text-white font-bangla text-start">
            উপস্থিতি এবং ফলাফল
          </p>
          <p className="text-xl text-white font-bangla text-start">
            {" "}
            অনলাইনের মাধ্যমে ফি প্রদান করুন{" "}
          </p>
          <p className="text-xl text-white font-bangla text-start">
            অর্থপ্রদান এবং বকেয়া স্ট্যাটাস
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentPortal;
