import React from "react";
import Main_feature from "../../assets/main_feature.svg";

const MainFeature = () => {
  return (
    <div className="flex flex-col gap-7 items-center bg-[#f4f7fa] w-screen py-20 mt-10">
      <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-grad rounded font-bangla">
        “স্কুল৩৬০” শিক্ষা ব্যবস্থাপনা সফটওয়্যার
      </h1>
      <h1 className="text-3xl font-bold font-bangla">মূল মডিউল</h1>
      <img
        src={Main_feature}
        alt="Main_feature"
        className="w-3/4 md:w-[50rem]"
      />
    </div>
  );
};

export default MainFeature;
