import React from "react";
import Banner from "./Banner/Banner";
import Relations from "./Relations";
import MainFeature from "./MainFeature";
import OtherModule from "./OtherModule";
import SinglePointSolution from "./SinglePointSolution";
import ReportSection from "./ReportSection";
import SmsSection from "./SmsSection";
import Benefits from "./Benefits";
import FastSection from "./FastSection";
import DistrictSection from "./DistrictSection";
import AwardSection from "./AwardSection";
import AgencySection from "../../AgencySection";
import RatingSection from "./RatingSection";
import AccessSection from "./AccessSection";
import ReviewSection from "./ReviewSection";

const Dashboard = () => {
  return (
    <div>
      <Banner />
      <Relations />
      <MainFeature />
      <OtherModule />
      <SinglePointSolution />
      <ReportSection />
      <SmsSection />
      <Benefits />
      <FastSection />
      <DistrictSection />
      <FastSection />
      <AwardSection />
      <AgencySection />
      <RatingSection />
      <AccessSection />
      <ReviewSection />
    </div>
  );
};

export default Dashboard;
