import React, { useEffect, useRef, useState } from "react";
import MD_FAIZUL_KARIM from "../../assets/MD._FAZLUL_KARIM.jpg";

export const reviews = [
  {
    image: MD_FAIZUL_KARIM,
    name: "MD. RUHUL MOAZZAM",
    position: "Head Teacher/Secretary",
    school: "Burirhat High School Mahanagar, Rangpur.",
    statements: [
      `With great pleasure I would like to take the opportunity to recommend Spate Initiative Limited.`,
      `The company has several products and amongst that, School360 is their most popular product. School360 is an educational institute management system with the idea to digitalize educational institutes. And with the use of School360, our education institute has taken a leap forward towards digitalization.`,
      `I have started using School 360, an educational institute management software, for the last few years. With this my institute has been running in a digitalized system. With School360, I have been able to conduct online admission, digital attendance through biometric devices for both teachers and students. Fees payment through online payment services. Also printing accessories like seat plan, admit card, ID card, results & mark sheet has made our life a whole lot easier. They have provided us sms services which helped us get a far more interactive response from both teachers and parents.`,
      `With the use of School360, our clerical works have greatly decreased and our time was immensely saved. Their support team has been diligently helping us throughout in every phases we go stuck.`,
      `With the use of School360, our clerical works have greatly decreased and our time was immensely saved. Their support team has been diligently helping us throughout in every phases we go stuck.`,
      `We wish School360, the best of luck for their endeavour in their upcoming ideas to revolutionize the Education sector in Bangladesh through ICT.`,
    ],
  },
  {
    image: MD_FAIZUL_KARIM,
    name: "MD. RUHUL MOAZZAM 2",
    position: "Vice Teacher/Secretary",
    school: "Burirhat High School Mahanagar, Rangpur.",
    statements: [
      `With great pleasure I would like to take the opportunity to recommend Spate Initiative Limited.`,
      `The company has several products and amongst that, School360 is their most popular product. School360 is an educational institute management system with the idea to digitalize educational institutes. And with the use of School360, our education institute has taken a leap forward towards digitalization.`,
      `I have started using School 360, an educational institute management software, for the last few years. With this my institute has been running in a digitalized system. With School360, I have been able to conduct online admission, digital attendance through biometric devices for both teachers and students. Fees payment through online payment services. Also printing accessories like seat plan, admit card, ID card, results & mark sheet has made our life a whole lot easier. They have provided us sms services which helped us get a far more interactive response from both teachers and parents.`,
      `With the use of School360, our clerical works have greatly decreased and our time was immensely saved. Their support team has been diligently helping us throughout in every phases we go stuck.`,
      `With the use of School360, our clerical works have greatly decreased and our time was immensely saved. Their support team has been diligently helping us throughout in every phases we go stuck.`,
      `We wish School360, the best of luck for their endeavour in their upcoming ideas to revolutionize the Education sector in Bangladesh through ICT.`,
    ],
  },
];

const ReviewSection = () => {
  const myElementRef = useRef();
  const [slideIndex, setSlideIndex] = useState(1);

  // let slideIndex = 1;
  showSlides(slideIndex);

  // Thumbnail image controls
  function currentSlide(n) {
    showSlides(setSlideIndex(n));
  }

  useEffect(() => {
    currentSlide(1);
  }, []);

  function showSlides(n) {
    let i;
    if (myElementRef.current) {
      let slides = myElementRef.current.getElementsByClassName("mySlides");
      let dots = myElementRef.current.getElementsByClassName("dot");
      if (n > slides.length) {
        setSlideIndex(1);
      }
      if (n < 1) {
        setSlideIndex(slides.length);
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace("active", "");
      }
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
    }
  }

  return (
    <div className="slideshow-container" ref={myElementRef}>
      {reviews.map((review, i) => (
        <div
          key={i}
          className="mySlides fade w-5/6 mx-auto border-[#eaeaea] border-2 rounded-xl p-16"
        >
          <div className="mb-7">
            <img
              src={review.image}
              alt=""
              className="rounded-full w-12 text-[#081828] mx-auto mb-3"
            />
            <h1 className="font-extrabold">{review.name}</h1>
            <h3 className="font-bold text-sm">({review.position})</h3>
            <h5 className="text-[#888] text-sm">{review.school}</h5>
          </div>
          <div>
            {review.statements.map((stmnt, i) => (
              <p
                key={i}
                className="text-[#888888] font-semibold text-start mb-8 "
              >
                {stmnt}
              </p>
            ))}
          </div>
        </div>
      ))}

      <div style={{ textAlign: "center" }} className="my-10">
        {reviews.map((rev, i) => (
          <span
            className="dot"
            onClick={() => currentSlide(i + 1)}
            key={i}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ReviewSection;
