import React, { useState } from "react";
import supported_by from "../../assets/supported_by.png";
import CascadingDropdownExample from "./CascadingDrop";
import useTitle from "../hooks/useTitle";

const Registration = () => {
  useTitle("আমাদের পরিবারে যোগ দিন");
  const [selectedEducation, setSelectedEducation] = useState("");
  return (
    <form className="md:w-4/5 lg:w-3/5 md:mx-auto mx-10 border border-[#0d6efd] rounded px-5 py-10 my-10 mt-20">
      <img src={supported_by} alt="" className="w-2/6 mx-auto mb-6" />
      <div>
        <p className="text-[#888] text-sm font-bold text-start mb-3">
          Apply For? (কোন পদের জন্য আবেদন করছেন?)
          <span className="text-red-500 text-2xl">*</span>
        </p>
        <div className="flex items-center gap-2">
          <input
            type="radio"
            id="radio1"
            name="Affiliate Partner"
            value="সহযোগী অংশীদার - Affiliate Partner"
          />
          <label
            htmlFor="radio1"
            className="text-[#888] font-bangla font-bold text-start "
          >
            সহযোগী অংশীদার - Affiliate Partner
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="radio"
            id="radio2"
            name="White Level Partner"
            value="HTML"
          />
          <label
            htmlFor="radio2"
            className="text-[#888] font-bangla font-bold text-start"
          >
            "হোয়াইট লেভেল" (নিজস্ব প্রতিষ্ঠানের নামে) - White Level Partner
          </label>
        </div>
      </div>
      <div>
        <div className="flex flex-col mt-3">
          <label
            htmlFor="name"
            className="text-[#888] font-bangla font-bold text-start"
          >
            Name (নাম) <span className="text-red-500 text-2xl">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="Name"
            placeholder="ENTER YOUR NAME"
            className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
          <div className="flex flex-col">
            <label
              htmlFor="email"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Email (ইমেইল )
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="ENTER YOUR EMAIL"
              className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="mobile"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Personal Mobile (ব্যাক্তিগত মোবাইল)
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              placeholder="ENTER YOUR MOBILE"
              className="text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#505051]"
            />
            <p className="font-bangla text-sky-400 text-start text-sm flex items-center gap-1">
              <span className="text-2xl">***</span> এই নাম্বারে SMS পাঠনো হবে
            </p>
          </div>
        </div>
        <CascadingDropdownExample />
        <div className="lg:grid grid-cols-3 gap-3">
          <div className="flex flex-col mt-3">
            <label
              htmlFor="education"
              className="text-[#888] font-bangla font-bold text-start"
            >
              Education Qualification (সর্বশেষ শিক্ষাগত যোগ্যতা)
              <span className="text-red-500 text-2xl">*</span>
            </label>
            <select
              name="education"
              id="education"
              value={selectedEducation}
              onChange={(e) => setSelectedEducation(e.target.value)}
              className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#000] appearance-none"
            >
              <option value="" selected="selected">
                SELECT - নির্বাচন করুন
              </option>
              <option value="SSC - এস.এস.সি" selected="selected">
                SSC - এস.এস.সি
              </option>
              <option value="HSC - এইচ.এস.সি" selected="selected">
                HSC - এইচ.এস.সি
              </option>
              <option value="BSC - বি.এস.সি" selected="selected">
                BSC - বি.এস.সি
              </option>
            </select>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-[#888] font-bold text-start text-sm">
            Have You? (নিচে কোনটি আপনার আছে?)
          </p>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="checkbox1"
              name="Smartphone"
              value="Smartphone - স্মার্টফোন"
            />
            <label
              htmlFor="checkbox1"
              className="text-[#888] font-bold text-start text-sm"
            >
              Smartphone - স্মার্টফোন
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="checkbox2"
              name="Laptop"
              value="Laptop - ল্যাপটপ"
            />
            <label
              htmlFor="checkbox2"
              className="text-[#888] font-bold text-start text-sm"
            >
              Laptop - ল্যাপটপ
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="checkbox3"
              name="Desktop Computer"
              value="Desktop Computer - ডেস্কটপ কম্পিউটার"
            />
            <label
              htmlFor="checkbox3"
              className="text-[#888] font-bold text-start text-sm"
            >
              Desktop Computer - ডেস্কটপ কম্পিউটার
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="checkbox4"
              name="Motorcycle"
              value="Motorcycle - মোটরসাইকেল"
            />
            <label
              htmlFor="checkbox4"
              className="text-[#888] font-bold text-start text-sm"
            >
              Motorcycle - মোটরসাইকেল
            </label>
          </div>
        </div>
        <div className="my-5">
          <p className="text-[#888] font-bold text-start text-sm">
            Current Position (বর্তমানে আপনি কি করছেন সংক্ষিপ্ত আকারে লিখুন)
          </p>
          <textarea
            name="Position"
            id="position"
            cols="30"
            rows="7"
            className="border border-black mt-5 w-full rounded"
          ></textarea>
        </div>
        <button className="text-white bg-[#dc3545] w-fit rounded py-2 px-3 flex">
          আবেদন করুন - Submit
        </button>
      </div>
    </form>
  );
};

export default Registration;
