import React from "react";
import useTitle from "../hooks/useTitle";
import award from "../../assets/awards-ict-1.jpg";
import { Link } from "react-router-dom";

const MediaEvent = () => {
  useTitle("সংবাদমাধ্যম এবং মুহূর্ত");
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:px-28 py-20">
      <div>
        <Link to="/">
          <img src={award} alt="" />
        </Link>
      </div>
      <div>
        <Link to="/">
          <img src={award} alt="" />
        </Link>
      </div>
      <div>
        <Link to="/">
          <img src={award} alt="" />
        </Link>
      </div>
      <div>
        <Link to="/">
          <img src={award} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default MediaEvent;
