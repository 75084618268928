import React, { useState } from "react";
import Navbar from "../components/Shared/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import FooterSection from "../components/Shared/FooterSection";
import ChildNav from "./ChildNav";

const Main = () => {
  const router = useLocation();
  const [childNavbar, setChildNavbar] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setChildNavbar(true);
    } else {
      setChildNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div>
      {router.pathname !== "/" && (
        <div className="breadCrumb fixed top-0 z-50 w-full">
          <Navbar />
          {!childNavbar && <ChildNav path={router.pathname} />}
        </div>
      )}
      <div
        className={`${router.pathname !== "/" && "relative"} ${
          !childNavbar ? "top-52" : "top-28"
        }`}
      >
        <Outlet />
        <FooterSection />
      </div>
    </div>
  );
};

export default Main;
