import React from "react";
import supported_by from "../../assets/supported_by.png";
import img1 from "../../assets/1.webp";
import useTitle from "../hooks/useTitle";

const AboutUs = () => {
  useTitle("আমাদের সম্পর্কে");
  return (
    <div>
      <img src={supported_by} alt="" className="w-2/5 mx-auto" />
      <div className="flex flex-col gap-5 my-5 px-28">
        <p className="text-[#888] text-start">
          স্পেট ইনিশিয়েটিভ লিমিটেড ২০১৫ সালে প্রতিষ্ঠিত একটি দ্রুত সম্প্রসারিত
          সফটওয়্যার কোম্পানি যার সাপোর্ট সেন্টার: ১-এ দিল মোহাম্মদ রোড, হাউস
          নং-০৭, ব্লক-এ, ঢাকা উদ্যান, মোহাম্মদপুর, ঢাকা-১২০৭। স্পেট ইনিশিয়েটিভ
          লিমিটেড ২০১৫ সাল থেকে লিমিটেড কোম্পানি হিসাবে পরিষেবা দিয়ে আসছে। শুরু
          থেকেই বিভিন্ন ধরণের ওয়েব সল্যুশন এবং ডেভেলপমেন্ট ও অন্যান্য সেবা দিয়ে
          আসছে যার মাধ্যমে গ্রাহক এর সফলতা ও উন্নতি হচ্ছে।
        </p>
        <p className="text-[#888] text-start">
          আমরা দক্ষ, অভিজ্ঞ, উদ্ভাবনী, উদ্যমী, এবং নিবেদিত আইটি বিশেষজ্ঞদের একটি
          দল যারা ক্লায়েন্টদের সর্বোত্তম পরিষেবা দেওয়ার জন্য সবসময় প্রস্তুত।
          আমাদের ডেডিকেটেড বিজনেস ডেভেলপমেন্ট এবং সাপোর্ট টিম রয়েছে সব সময় আপনার
          প্রশ্নগুলি শোনার জন্য এবং সময়মত সেরা সেবা দেয়ার জন্য।
        </p>
        <p className="text-[#888] text-start">
          আমরা শিক্ষা, ব্যবসা অটোমেশন, এইচআর-পেরোল, অ্যাকাউন্টিং, ইনভেন্টরি
          ম্যানেজমেন্ট, ই-কমার্স এবং ওয়েবসাইট ডেভেলপমেন্টের এর জন্য সফটওয়্যার
          পরিষেবা (SaaS ভিত্তিক) এবং সেরা আই টি সমাধান দিয়ে থাকি। অন্যান্য
          সফটওয়্যার এবং ওয়েব অ্যাপ্লিকেশনগুলির পাশাপাশি, আমাদের জনপ্রিয়
          সফটওয়্যার গুলির মধ্যে রয়েছে ‍“স্কুল৩৬০” শিক্ষা প্রতিষ্ঠান
          ব্যবস্থাপনা সফটওয়্যার “স্কুল৩৬০” যা "ন্যাশনাল আইসিটি
          অ্যাওয়ার্ডস-২০২০" চ্যাম্পিয়ন পুরস্কার প্রাপ্ত ৷
        </p>
      </div>
      <div className="grid grid-cols-3 gap-5 my-20 px-28">
        <div className="shadow-2xl p-10 ">
          <h4 className="text-start font-bold mb-5">স্থাপিত</h4>
          <h1 className="text-sky-400 font-bold text-4xl text-start">২০১৫</h1>
        </div>
        <div className="shadow-2xl p-10 ">
          <h4 className="text-start font-bold mb-5">স্থাপিত</h4>
          <h1 className="text-sky-400 font-bold text-4xl text-start">২০১৫</h1>
        </div>
        <div className="shadow-2xl p-10 ">
          <h4 className="text-start font-bold mb-5">স্থাপিত</h4>
          <h1 className="text-sky-400 font-bold text-4xl text-start">২০১৫</h1>
        </div>
      </div>
      <div className="bg-[#f4f7fa] py-16">
        <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit mx-auto">
          আমাদের গ্রাহক
        </h1>
        <div className="grid grid-cols-6 gap-7 px-28 py-20">
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
          <img
            src={img1}
            alt=""
            className="p-4 bg-white rounded-lg shadow-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
