import React from "react";

const times = [
  { icon: "", time: "30 সেকেন্ডে", work: "নতুন ছাত্র নিবন্ধন" },
  { icon: "", time: "20 সেকেন্ডে", work: "অনলাইন ভর্তি" },
  { icon: "", time: "10 সেকেন্ডে", work: "ফি পেমেন্ট" },
  { icon: "", time: "20 সেকেন্ডে", work: "ফলাফল প্রকাশ করুন" },
];

const FastSection = () => {
  return (
    <div className="fast w-screen mb-20 pt-5">
      <h1 className="text-4xl font-bold font-bangla text-white my-24">
        কিভাবে আপনার স্কুল/কলেজ আগের চেয়ে দ্রুত হবে
      </h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 px-20">
        {times.map((time, i) => (
          <div className="mb-24" key={i}>
            <p className="bg-white w-fit m-auto px-6 py-4 rounded-lg font-bold mb-8">
              ic
            </p>
            <h1 className="text-4xl font-bold font-bangla text-white mb-2">
              {time.time}
            </h1>
            <h1 className="text-lg font-bold font-bangla text-white">
              {time.work}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FastSection;
