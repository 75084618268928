import React from "react";

const ChildNav = ({ path }) => {
  return (
    <div className="pb-16 pt-5">
      <h1 className="text-white text-2xl font-bold mb-3">
        {path === "/software-features"
          ? "“স্কুল৩৬০” সফটওয়্যার ফীচার"
          : path === "/about-us"
          ? "স্পেট ইনিশিয়েটিভ লিমিটেড সম্পর্কে"
          : path === "/our-management-team"
          ? "আমাদের ব্যবস্থাপনা টীম"
          : path === "/teacher-comments"
          ? "সফটওয়্যারটি ব্যবহারের পর শিক্ষকদের মন্তব্য"
          : path === "/news-and-events"
          ? "সংবাদমাধ্যম এবং মুহূর্ত"
          : path === "/photo-gallery"
          ? "ফটো গ্যালারি"
          : path === "/join-us"
          ? "আবেদন করুন"
          : path === "/reg"
          ? "আমাদের পরিবারে যোগ দিন"
          : path === "/iir"
          ? "সফটওয়্যার ব্যবহার করতে চাই"
          : path === "/faq"
          ? " প্রশ্ন উত্তর"
          : ""}
      </h1>
      <h5 className="text-white text-sm">
        মূল পাতা -{" "}
        <span>
          {path === "/software-features"
            ? "ফীচার"
            : path === "/about-us"
            ? "আমাদের সম্পর্কে"
            : path === "/our-management-team"
            ? "আমাদের ব্যবস্থাপনা টীম"
            : path === "/teacher-comments"
            ? "শিক্ষকদের মন্তব্য"
            : path === "/news-and-events"
            ? "সংবাদমাধ্যম এবং মুহূর্ত"
            : path === "/photo-gallery"
            ? "ফটো গ্যালারি"
            : path === "/join-us"
            ? "আবেদন করুন"
            : path === "/reg"
            ? "আবেদন ফর্ম"
            : path === "/iir"
            ? "সফটওয়্যার ব্যবহারের আবেদন ফর্ম"
            : path === "/faq"
            ? " প্রশ্ন উত্তর"
            : ""}
        </span>
      </h5>
    </div>
  );
};

export default ChildNav;
