import React, { useState } from "react";
import { geoInfo } from "./DistrictInfo";

const CascadingDropdownExample = () => {
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedUpazilla, setSelectedUpazilla] = useState("");

  const handleSubjectChange = (e) => {
    setSelectedDivision(e.target.value);
    setSelectedDistrict("");
    setSelectedUpazilla("");
  };

  const handleTopicChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedUpazilla("");
  };

  const subjectOptions = Object.keys(geoInfo).map((subject) => (
    <option key={subject} value={subject}>
      {subject}
    </option>
  ));

  const topicOptions =
    selectedDivision && geoInfo[selectedDivision] ? (
      Object.keys(geoInfo[selectedDivision]).map((topic) => (
        <option key={topic} value={topic}>
          {topic}
        </option>
      ))
    ) : (
      <option value="">Please select division first</option>
    );

  const chapterOptions =
    selectedDivision &&
    selectedDistrict &&
    geoInfo[selectedDivision][selectedDistrict] ? (
      geoInfo[selectedDivision][selectedDistrict].map((chapter) => (
        <option key={chapter} value={chapter}>
          {chapter}
        </option>
      ))
    ) : (
      <option value="">Please select district first</option>
    );

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div className="flex flex-col mt-3">
          <label
            htmlFor="division"
            className="text-[#888] font-bangla font-bold text-start"
          >
            Division (বিভাগ) <span className="text-red-500 text-2xl">*</span>
          </label>
          <select
            name="division"
            id="division"
            value={selectedDivision}
            onChange={handleSubjectChange}
            className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#000] appearance-none"
          >
            <option value="" selected="selected">
              SELECT - নির্বাচন করুন
            </option>
            {subjectOptions}
          </select>
        </div>
        <div className="flex flex-col mt-3">
          <label
            htmlFor="district"
            className="text-[#888] font-bangla font-bold text-start"
          >
            District (জেলা) <span className="text-red-500 text-2xl">*</span>
          </label>
          <select
            name="district"
            id="district"
            value={selectedDistrict}
            onChange={handleTopicChange}
            className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#000] appearance-none"
          >
            <option value="" selected="selected">
              SELECT - নির্বাচন করুন
            </option>
            {topicOptions}
          </select>
        </div>{" "}
        <div className="flex flex-col mt-3">
          <label
            htmlFor="upazilla"
            className="text-[#888] font-bangla font-bold text-start"
          >
            Upazila (উপজেলা) <span className="text-red-500 text-2xl">*</span>
          </label>
          <select
            name="upazilla"
            id="upazilla"
            value={selectedUpazilla}
            onChange={(e) => setSelectedUpazilla(e.target.value)}
            className=" ring-1 ring-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-200 rounded px-3 py-2 placeholder-[#000] appearance-none"
          >
            <option value="" selected="selected">
              SELECT - নির্বাচন করুন
            </option>
            {chapterOptions}
          </select>
        </div>
      </div>
    </div>
  );
};

export default CascadingDropdownExample;
