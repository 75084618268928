import React from "react";
import g1 from "../../assets/g1.webp";
import useTitle from "../hooks/useTitle";

const PhotoGallery = () => {
  useTitle("ফটো গ্যালারি");
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 px-28 py-16">
      <div className="p-3 shadow-xl rounded-lg">
        <img src={g1} alt="" />
        <h1 className="text-2xl font-bold py-4">5th Foundation Day</h1>
      </div>
      <div className="p-3 shadow-xl rounded-lg">
        <img src={g1} alt="" />
        <h1 className="text-2xl font-bold py-4">5th Foundation Day</h1>
      </div>
    </div>
  );
};

export default PhotoGallery;
