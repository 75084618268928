import React from "react";

const FeatureCard = ({ image, features, direc, backg }) => {
  return (
    <div className={`bg-[#${backg}] w-screen py-20 px-20`}>
      <div className="mb-12">
        <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit mx-auto ">
          সহজ এবং স্মার্ট
        </h1>
        <h1 className="text-5xl text-grad font-bold font-bangla mt-4 mb-6">
          অনলাইন ভর্তি ব্যবস্থাপনা
        </h1>
        <p className="text-[#888]">
          শিক্ষার্থীগণ বাসা থেকে অনলাইনে ভর্তির আবেদন করতে পারবে
        </p>
      </div>
      <div
        className={`flex flex-col lg:${direc} gap-10 items-start lg:justify-around lg:items-center w-fit mx-auto`}
      >
        <div>
          {features.map((ftr, i) => (
            <p className="text-start text-xl my-4" key={i}>
              {ftr}
            </p>
          ))}
        </div>
        <img src={image} alt="" className="bg-white p-3 rounded-lg shadow-lg" />
      </div>
    </div>
  );
};

export default FeatureCard;
