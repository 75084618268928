import React, { useState } from "react";
import white_logo from "../../assets/white-logo.png";
import colour_logo from "../../assets/colour-logo.png";
import { Link } from "react-router-dom";
import ToggleBar from "./ToggleBar";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [toggle, setToggle] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div>
      <div
        className={`flex items-center justify-between px-5 md:px-28 pt-6 pb-5 ${
          colorChange && "bg-white pb-6 shadow-lg"
        }`}
      >
        <img
          src={colorChange ? colour_logo : white_logo}
          alt=""
          className="w-56"
        />
        <div className="flex lg:hidden items-center gap-5">
          <Link
            to="/iir"
            className={`${
              colorChange && "bg-grad"
            } font-bangla text-lg w-36 text-white border-2 border-white hover:text-black hover:bg-white hover:cursor-pointer rounded-md`}
          >
            সফটওয়্যার ব্যবহার করতে চাই
          </Link>
          <div>
            <button
              className={`${
                colorChange && "bg-grad"
              } font-bangla text-lg px-2 py-1 text-white border-2 border-white hover:text-black hover:bg-white hover:cursor-pointer rounded-md`}
              onClick={() => setToggle(!toggle)}
            >
              |||
            </button>
          </div>
        </div>

        <div className="lg:flex gap-10 items-center hidden">
          <Link
            to="/"
            className={`${
              colorChange ? "text-black" : "text-white"
            } font-semibold font-bangla text-xl hover:cursor-pointer`}
          >
            মূল পাতা
          </Link>
          <Link
            to="/software-features"
            className={`${
              colorChange ? "text-black" : "text-white"
            } font-semibold font-bangla text-xl hover:cursor-pointer`}
          >
            ফীচার
          </Link>
          <div className="dropdown">
            <nav
              className={`${
                colorChange ? "text-black" : "text-white"
              } font-semibold font-bangla text-xl hover:cursor-pointer`}
            >
              কোম্পানি
            </nav>
            <div className="dropdown-content shadow-lg">
              <Link to="/about-us" className="text-sm text-start">
                আমাদের সম্পর্কে
              </Link>
              <Link to="/our-management-team" className="text-sm text-start">
                ব্যবস্থাপনা টিম
              </Link>
              <Link to="/teacher-comments" className="text-sm text-start">
                শিক্ষকদের মন্তব্য
              </Link>
              <Link to="/news-and-events" className="text-sm text-start">
                সংবাদমাধ্যম এবং মুহূর্ত
              </Link>
              <Link to="/photo-gallery" className="text-sm text-start">
                ফটো গ্যালারি
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <nav
              className={`${
                colorChange ? "text-black" : "text-white"
              } font-semibold font-bangla text-xl hover:cursor-pointer`}
            >
              সল্যুশন
            </nav>
            <div className="dropdown-content shadow-lg">
              <Link to="/faq" className="text-sm text-start">
                প্রশ্ন উত্তর
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <nav
              className={`${
                colorChange ? "text-black" : "text-white"
              } font-semibold font-bangla text-xl hover:cursor-pointer`}
            >
              পার্টনার
            </nav>
            <div className="dropdown-content shadow-lg">
              <Link to="/join-us" className="text-sm text-start">
                রেজিস্ট্রেশন করুন
              </Link>
              <Link to="/login" className="text-sm text-start">
                লগইন করুন
              </Link>
            </div>
          </div>
          <Link
            to="/iir"
            className={`${
              colorChange && "bg-grad"
            } font-bangla text-lg w-36 text-white border-2 border-white hover:text-black hover:bg-white hover:cursor-pointer rounded-md`}
          >
            সফটওয়্যার ব্যবহার করতে চাই
          </Link>
        </div>
      </div>
      <ToggleBar colorChange={colorChange} toggle={toggle} />
    </div>
  );
};

export default Navbar;
