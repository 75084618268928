import React from "react";
import login_ballon from "../../assets/login-balloon.jpg";
import useTitle from "../hooks/useTitle";

const Login = () => {
  useTitle("Login");
  return (
    <div className="bg-login w-screen h-screen bg-no-repeat bg-cover">
      <div className="bg-white opacity-80 w-3/5 lg:w-4/5 mx-auto top-28 flex gap-20 items-center pe-16 relative">
        <img src={login_ballon} alt="" className="hidden lg:block lg:w-96" />
        <div className="grow px-16 py-28 lg:px-0 lg:py-0">
          <h1 className="text-start mb-7">Login to School360Family v3.0.1</h1>
          <form>
            <div>
              <label
                htmlFor="username"
                className="form-group has-float-label mb-7"
              >
                <input
                  type="text"
                  name="Username"
                  id="username"
                  className="form-control"
                />
                <span>Username</span>
              </label>
            </div>
            <div>
              <label
                htmlFor="password"
                className="form-group has-float-label mb-7"
              >
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                />
                <span>Password</span>
              </label>
            </div>
            <div className="flex justify-between items-center">
              <p className="text-[##21212] text-xs">Forget Password?</p>
              <button className="bg-[#145388] hover:bg-[#1c374d] text-white rounded-3xl px-10 py-3 text-sm">
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
