import React from "react";
import about_img from "../../assets/about-img-right.png";
import abu_sayed from "../../assets/abu_sayed.png";
import useTitle from "../hooks/useTitle";

const Management = () => {
  useTitle("ব্যবস্থাপনা টীম");
  return (
    <div className="bg-[#f4f7fa] w-screen lg:px-20 py-20">
      <div className="flex flex-col lg:flex-row px-20 gap-10 lg:gap-28 items-center">
        <img
          src={about_img}
          alt=""
          className="bg-white p-3 rounded-lg shadow-xl w-2/4 lg:w-1/4"
        />
        <div className="w-full lg:w-3/4">
          <h1 className="text-2xl font-bold lg:text-start mb-2">
            সাজ্জাদুর রহমান
          </h1>
          <h3 className="text-xl font-bold lg:text-start ">
            ব্যবস্থাপনা পরিচালক ও প্রধান নির্বাহী কর্মকর্তা
          </h3>
          <h5 className=" font-bold lg:text-start mb-2">
            উদ্যোক্তা | প্রযুক্তি উৎসাহী | কোড উৎসাহী | চিন্তাবিদ | স্কুল৩৬০-এর
            প্রতিষ্ঠাতা
          </h5>
          <p className="text-[#888] text-start text-sm lg:text-lg">
            আমি কোডিং-এ দৃঢ় আগ্রহের সাথে একজন প্রযুক্তি-চালিত উৎসাহী । তথ্য
            প্রযুক্তির ক্ষেত্রে ১০ বছরেরও বেশি সময় ধরে কাজ করা আমাকে চাকরিতে
            এবং কাজের বাইরে অনেক অভিজ্ঞতা দিয়েছে। কোডিং-এর প্রতি আমার উৎসাহ
            আমাকে একটি ঝুঁকি নিতে এবং একটি এড-টেক প্ল্যাটফর্ম তৈরি করার লক্ষ্য
            অনুসরণ করতে পরিচালিত করেছিল । যা ছাত্র, শিক্ষক এবং অভিভাবকদের জন্য
            ওয়ান স্টপ সমাধান হিসাবে কাজ করবে। প্রযুক্তির প্রবর্তনের মাধ্যমে
            শিক্ষার স্তর উন্নত করাই লক্ষ্য। আমার বেল্টের অধীনে ১০০ টিরও বেশি
            প্রকল্পের সাথে, আপনি নিজের জন্য যে উদ্দেশ্যগুলি সেট করেছেন তা অর্জনে
            আমি আপনাকে এবং আপনার সংস্থাকে সহায়তা করার জন্য অনন্যভাবে যোগ্য।
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 px-20 md:px-10 py-20">
        <div className="flex flex-col">
          <img src={abu_sayed} alt="" />
          <div className="bg-red-500 py-2">
            <h1 className="text-white text-2xl">আবু সাইদ</h1>
            <h3 className="text-white text-sm"> সহযোগী নির্বাহী কর্মকর্তা</h3>
            <h5 className="text-white text-sm"> স্পেট ইনিশিয়েটিভ লিমিটেড</h5>
          </div>
        </div>
        <div className="flex flex-col">
          <img src={abu_sayed} alt="" />
          <div className="bg-red-500 py-2">
            <h1 className="text-white text-2xl">আবু সাইদ</h1>
            <h3 className="text-white text-sm"> সহযোগী নির্বাহী কর্মকর্তা</h3>
            <h5 className="text-white text-sm"> স্পেট ইনিশিয়েটিভ লিমিটেড</h5>
          </div>
        </div>
        <div className="flex flex-col">
          <img src={abu_sayed} alt="" />
          <div className="bg-red-500 py-2">
            <h1 className="text-white text-2xl">আবু সাইদ</h1>
            <h3 className="text-white text-sm"> সহযোগী নির্বাহী কর্মকর্তা</h3>
            <h5 className="text-white text-sm"> স্পেট ইনিশিয়েটিভ লিমিটেড</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
