import React from "react";

const Relations = () => {
  return (
    <div>
      <div className="flex flex-col gap-7 items-center">
        <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-grad rounded font-bangla">
          আমাদের সম্পর্কে
        </h1>
        <h1 className="text-3xl font-bold font-bangla">
          “স্কুল৩৬০” - শিক্ষা প্রতিষ্ঠানের জন্য সব
        </h1>
        <p className="flex flex-col text-start text-[#888] w-3/4 text-lg font-bangla">
          <span>
            “স্কুল৩৬০” একটি ক্লাউড-ভিত্তিক শিক্ষা প্রতিষ্ঠান ব্যবস্থাপনা
            সফটওয়্যার, যা স্কুল ব্যবস্থাপনা সফটওয়্যার নামেও পরিচিত। স্পেট
            ইনিশিয়েটিভ লিমিটেড বাংলাদেশের স্কুল, কলেজ, মাদ্রাসা, কোচিং সেন্টার
            এবং জাতীয় বিশ্ববিদ্যালয়ের অধিভুক্ত কলেজগুলির জন্য সফটওয়্যারটি
            ডিজাইন করেছে।
          </span>
          <br />
          <span>
            তথ্যপ্রযুক্তির এই যুগে দেশের শিক্ষা প্রতিষ্ঠানের ডিজিটালাইজেশন খুব
            জরুরি। শিক্ষা প্রতিষ্ঠান পরিচালনায় বিভিন্ন সমস্যা সমাধানের জন্য
            “স্কুল৩৬০” শিক্ষা প্রতিষ্ঠান ব্যবস্থাপনা সফটওয়্যারটি ২০১৫ সালে
            যাত্রা শুরু করে। “স্কুল৩৬০” স্কুল, কলেজ, মাদ্রাসা, কোচিং সেন্টার,
            পলিটেকনিক এবং ট্রেনিং সেন্টারে সফলভাবে ব্যবহৃত হচ্ছে। এটি যেকোনো
            জায়গা থেকে এবং যেকোনো স্মার্ট ডিভাইসে খুব সহজে ব্যবহার করা যায়।
            আপনার শিক্ষা প্রতিষ্ঠানের জন্য “স্কুল৩৬০” সফটওয়্যাটি আদর্শ ইআরপি
            সফটওয়্যার, যা দক্ষতা বাড়ায়, প্রতিষ্ঠান পরিচালনা সহজ করে, ছাত্র ও
            অভিভাবকদের ভালো সেবা প্রদান নিশ্চিত করে এবং খরচ কমিয়ে প্রতিষ্ঠানের
            লাভজনকতা বাড়ায়। “স্কুল৩৬০” একটি অনন্য সফটওয়্যার যা সময়ের
            অপব্যবহার এবং কাজের পুনরাবৃত্তি রোধ করে প্রতিষ্ঠানের কর্মীদের দক্ষতা
            এবং কার্যকারিতা বাড়ায়। “স্কুল৩৬০” আপনাকে সমস্ত কার্যক্রমের
            সামগ্রিক স্বচ্ছতার আশ্বাস দেয়।
          </span>
          <br />
          <span>
            “স্কুল৩৬০” শিক্ষা প্রতিষ্ঠানের আধুনিকীকরণ এবং স্মুথ-ভাবে কাজগুলো
            পরিচালনায় বিশেষ অবদান রাখার জন্য ২০২০ সালে 'জাতীয় আইসিটি
            পুরুষ্কার'-এ চ্যাম্পিয়ন ভূষিত হয়। সুতরাং আমাদের অভিজ্ঞতা থেকে,
            আমরা বলতে পারি - আপনার প্রতিষ্ঠানকে ডিজিটাল প্রতিষ্ঠানে রূপান্তর
            করতে “স্কুল৩৬০” হবে সব থেকে সেরা সফটওয়্যার।
          </span>
        </p>
      </div>
    </div>
  );
};

export default Relations;
