import React from "react";
import white_logo from "../../assets/white-logo.png";
import supported_by from "../../assets/supported_by.png";
import sslcommerz from "../../assets/sslcommerz-footer-1024x48.png";
import footer_logo from "../../assets/footer_logo.png";

const FooterSection = () => {
  return (
    <div className="agency w-screen pb-8">
      <div className="grid grid-cols-1 gap-y-10 md:grid-cols-3 lg:grid-cols-foot md:px-5 md:gap-y-5 lg:px-20 pt-16 mb-6 lg:gap-16 px-10">
        <div className="flex flex-col gap-8 md:px-10">
          <img src={white_logo} alt="" className="w-80" />
          <p className="text-white font-bangla text-lg text-start">
            “স্কুল৩৬০” একটি ক্লাউড-ভিত্তিক শিক্ষা প্রতিষ্ঠান ব্যবস্থাপনা
            সফটওয়্যার, যা স্কুল ব্যবস্থাপনা সফটওয়্যার নামেও পরিচিত। স্পেট
            ইনিশিয়েটিভ লিমিটেড বাংলাদেশের স্কুল, কলেজ, মাদ্রাসা এবং কোচিং
            সেন্টারের জন্য সফটওয়্যার টি ডিজাইন করেছে।
          </p>
          <div className="flex md:grid md:grid-cols-4 lg:grid-cols-5 gap-4">
            <p className="border bottom-1 border-white w-fit py-2 px-3 rounded-md">
              ic
            </p>
            <p className="border bottom-1 border-white w-fit py-2 px-3 rounded-md">
              ic
            </p>
            <p className="border bottom-1 border-white w-fit py-2 px-3 rounded-md">
              ic
            </p>
            <p className="border bottom-1 border-white w-fit py-2 px-3 rounded-md">
              ic
            </p>
            <p className="border bottom-1 border-white w-fit py-2 px-3 rounded-md">
              ic
            </p>
          </div>
        </div>
        <div>
          <h1 className="font-bangla mb-5 text-2xl text-white text-start">
            আমাদের সম্পর্কে
          </h1>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            মূল পাতা
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            মডিউল
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ফীচার
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            বৈশিষ্ট্য
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            সফটওয়্যার মূল্য
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            আইডি রিকোয়েস্ট করুন
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ই-কমার্স
          </p>
        </div>
        <div>
          <h1 className="font-bangla mb-5 text-2xl text-white text-start">
            বৈশিষ্ট্য
          </h1>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ব্যবহার করা সহজ
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            সবসময় আপ টু ডেট
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ডিজিটাল হাজিরা সিস্টেম
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            শিক্ষার্থীদের অনলাইন বেতন আদায়
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ইন্টিগ্রেটেড এসএমএস
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ইন্টিগ্রেটেড ডাইনামিক ওয়েবসাইট
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            প্রয়োজন অনুসারে কাস্টমাইজ করার সুবিধা
          </p>
        </div>
        <div className="md:ps-10 lg:ps-0">
          <h1 className="font-bangla mb-5 text-2xl text-white text-start">
            যোগাযোগের তথ্য
          </h1>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            ১-এ দিল মোহাম্মদ রোড, হাউস নং-০৭, ব্লক-এ, ঢাকা উদ্যান, মোহাম্মদপুর,
            ঢাকা-১২০৭
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            hello@spatei.com
          </p>
          <p className="font-bangla mb-3 text-lg text-white text-start">
            অফিস: +৮৮০ ১৮৭৭ ৭৭৭১৪৯ সেলস: +৮৮০ ১৯৮৭ ৯৬৬৮৫১ সাপোর্ট: +৮৮০ ১৭৬৫
            ১৭৬৫৪৪
          </p>
        </div>
      </div>
      <hr />
      <div>
        <img src={supported_by} alt="" className="w-96 mx-auto my-10" />
        <img src={sslcommerz} alt="" className="w-4/5 mx-auto" />
      </div>
      <div>
        <p className="text-white font-bangla">
          কপিরাইট © ২০১৫ - ২০২৩, স্কুল৩৬০ | কতৃক সর্বস্বত্ব সংরক্ষিত.
        </p>
        <p className="text-white font-bangla">(কারিগরি সহযোগিতায় - স্কুল৩৬০)</p>
      </div>
      <img src={footer_logo} alt="" className="w-40 mx-auto" />
    </div>
  );
};

export default FooterSection;
