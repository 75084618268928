import React from "react";

const National = () => {
  return (
    <div>
      <div>
        <h1 className="text-xl font-bold text-start bg-[#ffe739] rounded font-bangla w-fit px-2 py-1">
          স্কুল, কলেজ, মাদ্রাসা, কোচিং সেন্টার এবং জাতীয় বিশ্ববিদ্যালয়ের
          অধিভুক্ত
        </h1>
        <h1 className=" text-5xl text-start text-white font-bold font-bangla mb-10">
          কলেজ গুলোর জন্য
        </h1>
        <h1 className="text-xl font-bold text-start text-[#ffe739] rounded font-bangla">
          পরিপূর্ণ ও সহজ
        </h1>
        <h1 className=" bg-[#ee413d] text-white rounded text-xl my-3 font-bangla w-fit  px-2 py-1">
          স্কুল ব্যবস্থাপনা সফটওয়্যার
        </h1>
        <div>
          <p className="text-xl text-white font-bangla text-start">
            অনলাইন ভর্তি
          </p>
          <p className="text-xl text-white font-bangla text-start">
            স্বয়ংক্রিয় উপস্থিতি
          </p>
          <p className="text-xl text-white font-bangla text-start">
            অনলাইন ফি/পেমেন্ট
          </p>
          <p className="text-xl text-white font-bangla text-start">
            তাৎক্ষণিক এসএমএস এবং আরও অনেক কিছু
          </p>
        </div>
      </div>
    </div>
  );
};

export default National;
