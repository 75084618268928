import React from "react";

import online_admission_management from "../../assets/online_admission_management.webp";
import bkash_payment from "../../assets/bkash_payment.png";
import school_college from "../../assets/school_college.png";
import student_portal_android_app from "../../assets/student_portal_android_app.png";
import bdmoney from "../../assets/bdmoney.jpg";
import FeatureCard from "./FeatureCard";
import useTitle from "../hooks/useTitle";

const features = [
  "অনলাইনে আবেদন ফরম পূরণ ও জমাদান",
  "অনলাইনে প্রয়োজনীয় ফাইল/ এটাচমেন্ট আপলোড",
  "অনলাইনে পেমেন্ট, মানি রিসিপ্ট ও এসএমএস কনফার্মেশন",
  "আবেদনকারীদের মেধা ভিত্তিক রিপোর্ট ও বাছাইকরণ",
  "অনলাইনে রিজেক্ট, ওয়েটিং লিষ্ট ও এডমিট কার্ড প্রাপ্তি",
  "বাল্ক এসএমএস/ নোটিফিকেশন প্রদানের ব্যবস্থা",
  "অনলাইনে ভর্তি পরীক্ষার ফলাফল প্রকাশ ও এসএমএস",
];

const Feature = () => {
  useTitle("ফীচার");
  return (
    <div>
      <FeatureCard
        image={online_admission_management}
        features={features}
        direc={"flex-row"}
        backg={"f4f7fa"}
      />
      <FeatureCard
        image={online_admission_management}
        features={features}
        direc={"flex-row"}
        backg={"fff"}
      />
      <div className="bg-[#f4f7fa] w-screen py-20">
        <h1 className="text-5xl text-grad font-bold font-bangla mt-4 mb-6">
          ফি সংগ্রহ করা
        </h1>
        <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit mx-auto mb-20">
          একদম সহজ
        </h1>
        <div className="hidden lg:flex justify-around mb-6">
          <div className="flex gap-4">
            <img src={bdmoney} alt="" className="w-16" />
            <img src={bdmoney} alt="" className="w-16" />
            <img src={bdmoney} alt="" className="w-16" />
          </div>
          <div className="flex gap-4">
            <img src={bdmoney} alt="" className="w-16" />
            <img src={bdmoney} alt="" className="w-16" />
            <img src={bdmoney} alt="" className="w-16" />
          </div>
        </div>
        <div className="flex lg:flex-row flex-col  items-center lg:items-start gap-6 justify-center">
          <img src={bkash_payment} alt="" />
          <img src={school_college} alt="" className="lg:w-96" />
          <img src={student_portal_android_app} alt="" />
        </div>
      </div>
      <FeatureCard
        image={online_admission_management}
        features={features}
        direc={"flex-row"}
        backg={"f4f7fa"}
      />
    </div>
  );
};

export default Feature;
