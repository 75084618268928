import React from "react";
import supported_by from "../../assets/supported_by.png";
import join_us from "../../assets/join-us.webp";
import { Link } from "react-router-dom";
import useTitle from "../hooks/useTitle";

const opportunities = [
  {
    name: "অ্যাফিলিয়েট পার্টনার",
    benefits: [
      `সরাসরি কোম্পানির সাথে চুক্তি বদ্ধ হয়ে নির্দিষ্ট এলাকার প্রতিনিধি হিসেবে দায়িত্ব পালন এবং সুবিধা উপভোগ করবেন।`,
      `ব্যবসায়ীক এলাকা নির্দিষ্ট থাকবে।`,
      `সফটওয়্যার থেকে মাসিক/ বাৎসরিক ধারাবাহিক ইনকাম করার সুবিধা আছে।`,
      `ভিজিটিং কার্ড/ আইডি কার্ড দেয়া হবে।`,
      `প্রোগ্রামে অংশগ্রহণ এবং সকল প্রকার ক্যাম্পেইনের সুবিধা উপভোগ করতে পারবেন।`,
      `বিজনেস অপারেশন এর জন্য Family Portal দেয়া হবে।`,
    ],
  },
  {
    name: '"হোয়াইট লেভেল"(নিজস্ব প্রতিষ্ঠানের নামে)',
    benefits: [
      ` "হোয়াইট লেভেল"(নিজস্ব প্রতিষ্ঠানের নামে)`,
      `নির্দিষ্ট কিছু প্রতিষ্ঠান নিয়ে আমাদের পলিসি অনুযায়ী কাজ করা যাবে।`,
      `কোন এরিয়া ভিত্তিক চুক্তি থাকবে না।`,
      `সেল করে দেয়ার পর এককালীন বা রেগুলার সেবা দেয়ার বিনিময়ে ধারাবাহিক ইনকাম করার সুযোগ আছে ।
চুক্তি করার হবে।`,
      `ভিজিটিং কার্ড/ আইডি কার্ড দেয়া হবে না।`,
      `কোম্পানির প্রোগ্রামে/ক্যাম্পেইনের অংশগ্রহণ বাধ্যতামূলক নয়।`,
      `বিজনেস অপারেশন এর জন্য পোর্টাল দেয়া হবে।`,
    ],
  },
];

const JoinUs = () => {
  useTitle("আবেদন করুন");
  return (
    <div className="bg-[#f4f7fa] w-screen px-32 py-20">
      <div>
        <h1 className="text-4xl font-bold font-bangla text-grad mb-20">
          আপনি কি স্কুল৩৬০ পরিবারের সাথে কাজ করতে আগ্রহী?
        </h1>
        <div className="grid gri lg:grid-cols-2 gap-10 place-items-center">
          <img src={join_us} alt="" className="bg-white p-3 rounded" />
          <div>
            <img src={supported_by} alt="" className="w-80 mx-auto  mb-7" />
            <div className="mb-10">
              <p className="text-start text-lg mb-5">
                আপনি যদি নিজস্ব এলাকায় একজন সফল উদ্যোক্তা হয়ে আয় করতে চান! তাহলে
                আপনার জন্য রয়েছে ‍“স্কুল৩৬০” পরিবারের সাথে কাজ করার সুযোগ। তাই
                বিলম্ব না করে Affiliate Partner হিসেবে যুক্ত হতে দ্রুত আবেদন
                করুন।
              </p>
              <p className="text-start text-lg">
                আর আপনি যদি নিজস্ব প্রতিষ্ঠানের মালিক হন তাহলে আপনার জন্য রয়েছে
                "হোয়াইট লেভেল"(নিজস্ব প্রতিষ্ঠানের নামে) কাজ করার সুযোগ।
              </p>
            </div>
            <Link to="/reg">
              <button className="w-full text-white bg-[#dc3545] rounded py-2">
                আবেদন করুন
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <h1 className="text-4xl font-bold font-bangla text-grad mb-20">
          অংশীদারিত্বের সুযোগ এবং সুবিধা
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {opportunities.map((opp, i) => (
            <table className="table-auto" key={i}>
              <thead>
                <tr>
                  <th className=" font-bangla text-2xl text-white bg-[#dc3545] py-2 rounded-s-sm rounded-e-sm">
                    {opp.name}
                  </th>
                </tr>
              </thead>
              <tbody>
                {opp.benefits.map((benefit, i) => (
                  <tr key={i}>
                    <td className="border-2 border-[#eaeaea] font-bangla text-lg text-[#888] py-1 px-4 text-start">
                      {benefit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
