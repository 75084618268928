import React from "react";
import basis_national_ict_award_2020 from "../../assets/basis_national_ict_award_2020.png";

const AwardSection = () => {
  return (
    <div className="pb-20">
      <h1 className="text-3xl bg-red-600 text-white px-4 py-1 bg-button_grad_2 rounded font-bangla w-fit m-auto mb-4">
        পুরস্কার
      </h1>
      <h1 className="text-4xl font-bold font-bangla mb-5">আমাদের অর্জন</h1>
      <p className="text-[#888]">
        আমরা জাতীয় আইসিটি পুরস্কার বিজয়ী সফটওয়্যার কোম্পানি। আমরা শিক্ষা
        ব্যবস্থাপনা সফটওয়্যার এ আমাদের শ্রেষ্ঠত্বের জন্য পুরস্কার পেয়েছি।
      </p>
      <div className="timeline flex flex-col items-center px-20">
        <div className="flex flex-col-reverse md:grid md:grid-cols-awardMobile gap-5 lg:grid-cols-award lg:gap-16 content-center mt-12">
          <img
            src={basis_national_ict_award_2020}
            alt=""
            className="md:w-80 lg:w-full"
          />
          <p className="hidden md:block rounded-full shadow-2xl shadow-slate-400 px-8 py-7 w-fit h-fit">
            ci
          </p>
          <div className="mt-5 w-72 lg:w-4/5">
            <h1 className="text-3xl text-start font-bold font-bangla mb-2">
              চ্যাম্পিয়ন, বেসিস জাতীয় আইসিটি পুরস্কার ২০২০
            </h1>
            <p className="text-[#888] text-start font-bangla text-lg">
              আমাদেরকে আমাদের স্কুল ব্যবস্থাপনা সফটওয়্যার এর জন্য পুরস্কৃত করা
              হয়েছে।
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-awardMobile gap-5 lg:grid-cols-award lg:gap-16 content-center mt-12">
          <div className="mt-5 w-72 lg:w-4/5">
            <h1 className="text-3xl text-start font-bold font-bangla mb-2">
              চ্যাম্পিয়ন, বেসিস জাতীয় আইসিটি পুরস্কার ২০২০
            </h1>
            <p className="text-[#888] text-start font-bangla text-lg">
              আমাদেরকে আমাদের স্কুল ব্যবস্থাপনা সফটওয়্যার এর জন্য পুরস্কৃত করা
              হয়েছে।
            </p>
          </div>
          <p className="hidden md:block rounded-full shadow-2xl shadow-slate-400 px-8 py-7 w-fit h-fit">
            ci
          </p>
          <img
            src={basis_national_ict_award_2020}
            alt=""
            className="md:w-80 lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AwardSection;
