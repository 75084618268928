import React from "react";

const AgencySection = () => {
  return (
    <div className="agency w-screen mb-20 py-32">
      <h1 className="text-3xl px-4 py-1 mb-4 bg-white rounded font-bangla w-fit mx-auto">
        ভূমিকা ভিডিও
      </h1>
      <h1 className="text-4xl text-white font-bold font-bangla mb-16">
        “স্কুল৩৬০” কিভাবে আপনার প্রতিষ্ঠানকে ডিজিটাল করবে
      </h1>
      <iframe
        src="https://www.youtube.com/embed/gV-Mf8cFlAk?si=bff4_Ws9-Gaphi_8"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className="m-auto h-96 w-96 md:h-[23rem] md:w-[42rem] lg:h-[415px] lg:w-[860px]"
      ></iframe>
    </div>
  );
};

export default AgencySection;
