import React from "react";

const Award = () => {
  return (
    <div>
      <h1 className="text-xl font-bold text-start text-[#ffe739] rounded font-bangla">
        চ্যাম্পিয়ন - পুরস্কার বিজয়ী
      </h1>
      <h1 className=" text-5xl text-start text-white font-bold font-bangla mb-10">
        স্কুল কলেজ ব্যবস্থাপনা সফটওয়্যার
      </h1>
      <h1 className="text-xl text-start text-white rounded font-bangla mb-16">
        চ্যাম্পিয়ন - পুরস্কার বিজয়ী
      </h1>
      <h1 className="text-xl font-bold text-start bg-[#ffe739] rounded font-bangla w-fit px-2 py-1 mb-5">
        মানসম্পন্ন সেবা দিয়ে শিক্ষার্থীদের সন্তুষ্ট করুন
      </h1>
      <h1 className="text-xl font-bold text-start bg-[#fff] rounded font-bangla w-fit px-2 py-1 mb-5">
        সময় এবং অর্থ সংরক্ষণ করুন
      </h1>
    </div>
  );
};

export default Award;
