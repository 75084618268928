import React from "react";
import service_chart from "../../assets/service_chart.webp";

const services = [
  {
    name: "নন-স্টপ সার্ভিস",
    des: "আমাদের সফটওয়্যারটি আপনার পরিষেবায় অবিরাম যা সর্বদা আপনাকে পরিবেশন করবে।",
  },
  {
    name: "২৪/৭ অ্যাক্সেসযোগ্য",
    des: "আপনি ইন্টারনেট সংযোগ সহ যেকোনো স্মার্ট ডিভাইস ব্যবহার করে যেকোনো স্থান থেকে যে কোনো সময় সফটওয়্যারটি অ্যাক্সেস করতে পারবেন।",
  },
  {
    name: "অটো রিপোর্ট",
    des: "প্রতিবেদনের জন্য আর নয় অপেক্ষা, সংক্রিয়ভাবে আপনার প্রয়োজন মত আর্থিক রিপোর্ট পাবেন।",
  },
];

const ReportSection = () => {
  return (
    <div className="bg-[#f4f7fa] lg:w-fit py-20 lg:flex justify-center items-center gap-6 px-8 md:px-28">
      <img src={service_chart} alt="" className="lg:w-1/2" />
      <div className="flex flex-col gap-7 lg:w-1/2">
        {services.map((service, i) => (
          <div key={i} className="flex  items-center gap-5">
            <p className="bg-button_grad_2 px-6 py-5 rounded-xl">ha</p>
            <div>
              <h1 className="text-2xl font-bold font-bangla text-start">
                {service.name}
              </h1>
              <p className="text-[#888] text-start font-bangla text-lg">
                {service.des}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportSection;
